.app-slide-wrapper {
  width: 100%;
  .spinner_place {
    .spinner_control {
      border-radius: 3px;
      bottom: 10px;
      padding: 5px;
      z-index: 1;
    }
  }

  .image-gallery-thumbnails {
    text-align: center;
  }

  .image-gallery-thumbnails-container {
    display: inline-flex;
    justify-content: center;
  }

  .image-gallery-thumbnail-inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  .image-gallery-slide-wrapper {
    margin-bottom: 20px;
  }

  .image-gallery-image {
    height: 400px;
    max-width: 100%;
  }

  .image-gallery-slide .image-gallery-image {
    width: auto;
  }

  .image-gallery-content.fullscreen {
    background: white;
    .image-gallery-image {
      height: 100vh;
      max-height: 100vh;
    }
  }

  .image-gallery-thumbnail {
    @extend .apartment-item-default;
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    width: 100px;
    height: 100px;

    @media (max-width: 900px) {
      width: 81px;
      height: 81px;
    }

    & + .image-gallery-thumbnail {
      margin: 10px;
    }

    .image-gallery-thumbnail-inner {
      display: flex;
      position: relative;
    }

    &.active,
    &:focus,
    &:hover {
      border: 1px solid var(--app-primary-color);
    }

    .image-gallery-thumbnail-image {
      max-width: 100%;
      max-height: 100%;
      width: auto !important;
    }
  }

  @media (max-width: 959.95px) {
    .image-gallery-image {
      height: 250px;
    }

    image-gallery-content.fullscreen {
      background: white;
      .image-gallery-image {
        height: auto;
      }
    }
  }

  .gallery-playcanvas {
    border: none;
    width: 100% !important;
  }

  @media (min-width: 1921px) {
    .image-gallery-image {
      height: 70vh;
    }
  }
}

.apartment-item-default {
  border: 1px solid #F2F2F2;
  border-radius: 10px;
}

.apartment-gallery-slide-item,
.apartment-gallery-spinner{
  @extend .apartment-item-default;
  //padding: 40px;
}

.apartment-gallery-thumbnail,
.apartment-gallery-thumbnail-spinner {
}