.app-slide-wrapper-v2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  justify-content: flex-start;
  border-radius: 8px;
  .spinner_place-v2 {
    .spinner_control-v2 {
      border-radius: 3px;
      bottom: 10px;
      padding: 5px;
      z-index: 1;
    }
  }

  .image-gallery-thumbnails {
    text-align: center;
    background-color: #F4F6F9;
    padding: 16px 0;
  }

  .image-gallery-thumbnails-container {
    display: inline-flex;
    justify-content: center;
    border: var(--app-primary-color);
    border-radius: 16px;
    background: #FFF;
  }

  .image-gallery-thumbnail-inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  .image-gallery-slide-wrapper {
    height: 100%;
    .image-gallery-swipe {
      height: 100%;
    }
    .image-gallery-slides {
      height: 100%;
      .image-gallery-slide {
        height: 100%;
        padding: 20px 64px;
        outline: none;
      }
      .image-gallery-image {
        height: 100%;
        max-height: unset;
      }
    }
    .image-gallery-icon {
      margin: 0 20px;
      padding: 50px 0;
      filter: none;
      outline: none;
      .image-gallery-svg {
        width: 16px;
      }
    }
  }

  .image-gallery-thumbnails-wrapper {
    position: absolute;
    left: 40%;
    bottom: -70px;
  }

  .image-gallery-image {
    //height: 400px;
    max-width: 100%;
  }

  .image-gallery-slide .image-gallery-image {
    width: auto;
    //max-height: calc(100vh - 40px)!important;
    max-height: max-content!important;
  }

  .image-gallery-content.fullscreen {
    background: white;
    .image-gallery-image {
      height: 100vh;
      max-height: 100vh;
    }
  }

  .image-gallery-thumbnail {
    border: 1px #FFFFFF;
    @extend .apartment-item-default-v2;
    cursor: pointer;
    padding: 10px;
    //margin: 10px;
    width: 100px;
    //height: 28px;
    color: #0037A6;

    @media (max-width: 900px) {
      width: 81px;
      height: 81px;
    }

    & + .image-gallery-thumbnail {
      //margin: 10px;
    }

    .image-gallery-thumbnail-inner {
      display: flex;
      position: relative;
    }

    .image-gallery-left-nav, .image-gallery-right-nav {
      margin: 0 20px;
    }

    &.active, &:hover {
      border: 1px solid var(--app-primary-color);
    }

    .image-gallery-thumbnail-image {
      max-width: 100%;
      max-height: 100%;
      width: auto !important;
    }
  }

  @media (max-width: 959.95px) {
    .image-gallery-image {
      height: 250px;
    }

    .image-gallery-content.fullscreen {
      background: white;
      .image-gallery-image {
        height: auto;
      }
    }
  }

  .gallery-playcanvas-v2 {
    border: none;
    width: 100% !important;
  }

  @media (min-width: 1921px) {
    .image-gallery-image {
      height: 70vh;
    }
  }
  .image-gallery-content {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  @media (max-width: 900px) {
    .image-gallery-slide-wrapper .image-gallery-slides .image-gallery-slide {
      padding: 0;
    }
    .image-gallery-fullscreen-button {
      display: none;
    }
    .image-gallery-content.fullscreen {
      min-height: unset;
      max-height: unset;
    }
    //.image-gallery-content {
    //  min-height: 216px;
    //  max-height: 216px;
    //}
  }
}

.apartment-item-default-v2 {
  //border: 1px solid #F2F2F2;
  border-radius: 10px;
}

.apartment-gallery-v2-slide-item,
.apartment-gallery-v2-spinner{
  display: flex!important;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  max-height: 100%;
  max-width: 100%;
  //justify-content: center;
  @extend .apartment-item-default-v2;
  //padding: 40px;
}

.apartment-gallery-v2-thumbnail,
.apartment-gallery-v2-thumbnail-spinner {
}
